import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Banner } from '../Banner';
import { Container } from '../Container';
import { Heading } from '../Heading';
import { Lede } from '../Lede';
import { Props, Playground } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "banner"
    }}>{`Banner`}</h1>
    <p>{`Banner is a `}<a parentName="p" {...{
        "href": "/components/Flex"
      }}>{`Flex`}</a>{` and is to be used to create a large banner with a background image.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Banner} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <h3 {...{
      "id": "main-props"
    }}>{`Main Props:`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`backgroundImage`}</inlineCode>{`: maps to CSS attribute `}<inlineCode parentName="li">{`background-image: url()`}</inlineCode>{` location of the background image`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`backgroundSize`}</inlineCode>{`: maps to CSS attribute `}<inlineCode parentName="li">{`background-size`}</inlineCode>{`, defaults to `}<inlineCode parentName="li">{`cover`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`backgroundPosition`}</inlineCode>{`: maps to CSS attribute `}<inlineCode parentName="li">{`background-position`}</inlineCode>{`, defaults to `}<inlineCode parentName="li">{`center center`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`backgroundRepeat`}</inlineCode>{`: maps to CSS attribute `}<inlineCode parentName="li">{`background-repeat`}</inlineCode>{`, defaults to `}<inlineCode parentName="li">{`no-repeat`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`shadow`}</inlineCode>{`: origin/dark side of the shadow gradient `}<a parentName="li" {...{
          "href": "#shadow"
        }}>{`see below`}</a>{`. Defaults to `}<inlineCode parentName="li">{`bottom`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`children`}</inlineCode>{`: use normal react children as the children. Often you'll want a `}<inlineCode parentName="li">{`<Container>`}</inlineCode>{` as your only child to contain text within.`}</li>
    </ul>
    <h2 {...{
      "id": "banner-as-the-site-title"
    }}>{`Banner as the site title`}</h2>
    <p>{`Use this setup for large page-headers.`}</p>
    <Playground __position={1} __code={'<Banner backgroundImage=\"http://placekitten.com/1001/700\">\n  <Container>\n    <Heading as=\"h1\">The main site title</Heading>\n    <Lede>A little intro to why users should read on</Lede>\n  </Container>\n</Banner>'} __scope={{
      props,
      DefaultLayout,
      Banner,
      Container,
      Heading,
      Lede,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Banner backgroundImage="http://placekitten.com/1001/700" mdxType="Banner">
    <Container mdxType="Container">
      <Heading as="h1" mdxType="Heading">The main site title</Heading>
      <Lede mdxType="Lede">A little intro to why users should read on</Lede>
    </Container>
  </Banner>
    </Playground>
    <h2 {...{
      "id": "different-height--no-picture"
    }}>{`Different height / No picture`}</h2>
    <p>{`Overwrite the default `}<inlineCode parentName="p">{`height`}</inlineCode>{` or set a `}<inlineCode parentName="p">{`bg`}</inlineCode>{` color rather than an image.`}</p>
    <Playground __position={2} __code={'<Banner bg=\"primary.dark\" height=\"50vh\">\n  <Container>\n    <Heading as=\"h2\" color=\"white\">\n      Less height... Smaller title\n    </Heading>\n  </Container>\n</Banner>'} __scope={{
      props,
      DefaultLayout,
      Banner,
      Container,
      Heading,
      Lede,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Banner bg="primary.dark" height="50vh" mdxType="Banner">
    <Container mdxType="Container">
      <Heading as="h2" color="white" mdxType="Heading">
        Less height... Smaller title
      </Heading>
    </Container>
  </Banner>
    </Playground>
    <h2 {...{
      "id": "shadow"
    }}>{`Shadow`}</h2>
    <h3 {...{
      "id": "shadow-position--direction"
    }}>{`Shadow Position / Direction`}</h3>
    <p>{`You can change the anchor of the shadow. Default is to `}<inlineCode parentName="p">{`bottom`}</inlineCode></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`bottom`}</inlineCode>{`: shadow is dark at the bottom, light at the top`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`right`}</inlineCode>{`: shadow is dark at the right, light at the left`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`top`}</inlineCode>{`: shadow is dark at the top, light at the bottom`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`left`}</inlineCode>{`: shadow is dark at the left, light at the right`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`none`}</inlineCode>{`: no shadow at all`}</li>
    </ul>
    <Playground __position={3} __code={'<Banner bg=\"primary.dark\" height=\"50vh\" shadow=\"none\">\n  <Container>\n    <Heading as=\"h2\" color=\"white\">\n      Some smaller title, maybe\n    </Heading>\n  </Container>\n</Banner>'} __scope={{
      props,
      DefaultLayout,
      Banner,
      Container,
      Heading,
      Lede,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Banner bg="primary.dark" height="50vh" shadow="none" mdxType="Banner">
    <Container mdxType="Container">
      <Heading as="h2" color="white" mdxType="Heading">
        Some smaller title, maybe
      </Heading>
    </Container>
  </Banner>
    </Playground>
    <h3 {...{
      "id": "shadow-color"
    }}>{`Shadow Color`}</h3>
    <p>{`You can change the start and end color of the shadow. The defaults are`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`shadowColorStart`}</inlineCode>{`: `}<inlineCode parentName="li">{`rgba(0,0,0,0.45)`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`shadowColorEnd`}</inlineCode>{`: `}<inlineCode parentName="li">{`rgba(0,0,0,0)`}</inlineCode></li>
    </ul>
    <Playground __position={4} __code={'<Banner\n  bg=\"primary.dark\"\n  height=\"50vh\"\n  shadow=\"left\"\n  shadowColorStart=\"red\"\n  shadowColorEnd=\"rgba(0,0,255,0.5)\"\n>\n  <Container>\n    <Heading as=\"h2\" color=\"white\">\n      Some smaller title, maybe\n    </Heading>\n  </Container>\n</Banner>'} __scope={{
      props,
      DefaultLayout,
      Banner,
      Container,
      Heading,
      Lede,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Banner bg="primary.dark" height="50vh" shadow="left" shadowColorStart="red" shadowColorEnd="rgba(0,0,255,0.5)" mdxType="Banner">
    <Container mdxType="Container">
      <Heading as="h2" color="white" mdxType="Heading">
        Some smaller title, maybe
      </Heading>
    </Container>
  </Banner>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      